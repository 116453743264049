var windowWidth = $(window).width();
var windowHeight = $(window).height();

$('#menu_btn').click(function(){
	
	$(this).find(".nav-icon").toggleClass('nav_open');
	$("#top_bar").toggleClass("off");
	$(".menu").toggleClass("on");
	
}); 

$(".menu_link").each(function(){
	$(this).click(function(){
		var section = $(this).attr("data-section");
		$(".menu_block").each(function(){
			if($(this).hasClass(section)){
				$(".main_block").addClass("off");
				$(this).addClass("on");
				$(".main_block").removeClass("on");
				$(this).removeClass("off");
			}
		});
		
	});
});

$(".menu_back").click(function(){
	$(".menu_block").each(function(){
		if($(this).hasClass("main_block")){
			$(this).addClass("on");
			$(this).removeClass("off");
		}else{
			$(this).removeClass("on");
		}
	});
});

$("#upload_btn").click(function(){
	$("#thefile").click();
	
});

$("input#thefile").change(function () {
    $("#file_save").prop('disabled', false);
    $(".doc_sel").addClass("on");
});

// Back button click ---------------------
$('.back').click(function(){
	parent.history.back();
	return false;
});

// Function to return address details from co-ordinates
function geocodePosition(pos){
	// Initialise the geocoder from Google maps
    geocoder = new google.maps.Geocoder();
    // Geocode the specified location
    geocoder.geocode({
        latLng: pos
    }, 
    function(results, status){
		// If the geolocation was successful
        if (status == google.maps.GeocoderStatus.OK){	
			// and fill the corresponding field on the form.
			for (var i = 0; i < results[0].address_components.length; i++) {
				// Get the current address data-type (Post code, street number etc.)
				var addressType = results[0].address_components[i].types[0];					
					// If the current address type is in the componentForm array then continue
					if (componentForm[addressType]) {
						// Get the value of the address data-type
						var val = results[0].address_components[i][componentForm[addressType]];
						// Find any form elements with the matching class name
						$("." + addressType).each(function(){
							// Give the form element the value from the current address data-type
							$(this).val(val);
						});
					}
				}
            } 
        }
    );
}

// Repeat event listener ---------------------
$("#repeat_select").change(function(){
	var value = $(this).val();
	if(value === "weekly"){
		$("#amount_holder").show();
		$(".interim").html("weeks");
	}else if(value === "monthly"){
		$("#amount_holder").show();
		$(".interim").html("months");
	}else{
		$("#amount_holder").hide();
		$(".interim").html("");
		$("#amount").val("");
	}
});

// Admin new event location add  ---------------------
$(".new_ev_add").change(function(){
	$("#loc_details").show();
});

// Notifications animation ---------------------
$("#notifications").hover(
  function() {
	$(".notification_box").removeClass("off bounceOutUp");
    $(".notification_box").addClass("on bounceInDown");
  }, function() {
    $(".notification_box").removeClass("on bounceInDown");
    $(".notification_box").addClass("off bounceOutUp");
  }
);
// Expandable article button click listener ---------------------
var thecounter = 0;
$("#article_expand").click(function(){

	if(thecounter === 0){
		$(".article_body").addClass("on");
		$("#article_expand").html("Read Less");
		thecounter++;
	}else{
		$(".article_body").removeClass("on");
		$("#article_expand").html("Read More");
		$('html, body').animate({
	        scrollTop: $(".article_body").offset().top - 70
	    }, 1000);
		thecounter = 0;
	}

});

// Expandable section click listener ---------------------
$(".expander").each(function(index, element){
	var counter = 0;
	$(element).find(".expand").click(function(){
		if(counter === 0){
			$(element).addClass("on");
			$(this).find("img").attr("src","/img/icons/svgs/minus.svg");
			counter++;
		}else{
			$(element).removeClass("on");
			$(this).find("img").attr("src","/img/icons/svgs/plus.svg");
			counter = 0;
		}
	});
});

// Fade effects on scroll ---------------------
function fadeEffects(){
	$('.scroll_fade').each(function(index, element){
		var effect = $(this).attr("data-fade");
	    var inview = new Waypoint({
	        element: element,
	        handler: function (direction) {
		        if(direction === "down"){
			        if($(element).hasClass("delay")){ 
				        setTimeout(function(){
					        $(element).addClass("animated " + effect);
							$(element).css('opacity','1');
				        }, 800);
			        }else if($(element).hasClass("delay_half")){
				        setTimeout(function(){
					        $(element).addClass("animated " + effect);
							$(element).css('opacity','1');
				        }, 400);
			        }else if($(element).hasClass("delay_quarter")){
				        setTimeout(function(){
					        $(element).addClass("animated " + effect);
							$(element).css('opacity','1');
				        }, 200);				        
			        }else{
				        $(element).addClass("animated " + effect);
						$(element).css('opacity','1');
			        }
				}
			},offset: '95%'
		});
	});
}

// Image upload buttons ---------------------
$("#choose_img1").click(function(){
	$("#img1").click();
});
$("#choose_img2").click(function(){
	$("#img2").click();
});
$("#choose_img3").click(function(){
	$("#img3").click();
});
$("#choose_img4").click(function(){
	$("#img4").click();
});
$("#img1").change(function(){
	$("#image_1 .image_selected").addClass("on");
});
$("#img2").change(function(){
	$("#image_2 .image_selected").addClass("on");
});
$("#img3").change(function(){
	$("#image_3 .image_selected").addClass("on");
});
$("#img4").change(function(){
	$("#image_4 .image_selected").addClass("on");
});

// Home page slideshow slider ---------------------
var home_slider = $("#home_slider .slider").bxSlider({
  auto: true,
  autoControls: true,
  speed: 500,
  mode: 'fade',
  randomStart: true,
  pause:5000
  
});

// Homepage news slider ---------------------
var news_slide_width = $("#news_slider").width() / 3;

var news_slider = $("#news_slider .slider").bxSlider({
  auto: true,
  autoControls: true,
  speed: 500,
  randomStart: true,
  slideWidth: news_slide_width,
  pause:8000,
  onSliderLoad: function(){
  	console.log("here");
  	var largestheight = 0;
  	$(".blog_post").each(function(){
  		var blogheight = $(this).height();
  		if(blogheight > largestheight){
  			largestheight = blogheight;
  		}
  	});
  	$(".blog_post").each(function(){
  		$(this).height(largestheight + "px");
  		$(this).find(".blog_post_inner").height((largestheight - 100) + "px");
  	});
  	$("#home_container").css("margin-top", "-" + (largestheight + 85) + "px");
  }  
});

$("#news_slider .slider").find(".slide_prev").each(function(){	
	$(this).click(function(){		
		news_slider.goToPrevSlide();
		news_slider.stopAuto();		
	});	
});

$("#news_slider .slider").find(".slide_next").each(function(){
	$(this).click(function(){
		news_slider.goToNextSlide();
		news_slider.stopAuto();
	});
});

// Home page upcoming events slider ---------------------
var upcoming_slider = $("#upcoming_slider .slider").bxSlider({
  auto: false,
  mode: "horizontal",
  autoControls: true,
  speed: 500,
  pause:6000
});

$("#upcoming_slider .slider").find(".slide_prev").each(function(){
	$(this).click(function(){
		upcoming_slider.goToPrevSlide();
		upcoming_slider.stopAuto();
	});
});

$("#upcoming_slider .slider").find(".slide_next").each(function(){
	$(this).click(function(){
		upcoming_slider.goToNextSlide();
		upcoming_slider.stopAuto();
	});
});

// Home page adverts slider ---------------------
var posting_slider = $("#posting_slider .slider").bxSlider({
  auto: false,
  mode: "vertical",
  autoControls: true,
  speed: 500,
  randomStart: true,
  auto:{
	  pause:5000
  }
});

$(document).ready(function(){

	// Check if the device is landscape
	var isLandscapeMode = window.innerWidth > window.innerHeight;
	
	// If it is landscape, show rotation hint
	if (isLandscapeMode) {
		if(windowWidth < 768){
			$("#orientation_change").css("display","block");
		}
	}else{
		$("#orientation_change").css("display","none");
	}

	// Clubs height match ---------------------
  	var highest = null;
	var hi = 0;
	$(".club_inner").each(function(){
		var h = $(this).height();
		if(h > hi){
			hi = h;
			highest = $(this);
		}
	});
	$(".club_inner").each(function(){
		$(this).height(hi + "px");
	});

	// Home square height match ---------------------
	var largesthomesquare = 0;
	$(".home_square").each(function(){
		largesthomesquare = $(this).width();
	});
	$(".home_square").each(function(){
		$(this).height(largesthomesquare + "px");
		if($(this).hasClass("postings")){
			$(this).height((largesthomesquare + 100) + "px");
		}
	});

	// Height match ---------------------
	$(".heightMatchContainer").each(function(){
	
	var theHeight = $(this).find(".heightMatch").height();
		$(this).find(".matchHeight").height(theHeight + "px");
		$(this).find(".matchHeightModuleInner").height((theHeight - 60) + "px");
	});

	// Admin alert box ---------------------
	if($(".alert_box")){
		setTimeout(function(){
			
			$(".alert_box").css("opacity","0");
			
		}, 1000);
		
		setTimeout(function(){
			
			$(".alert_box").hide();
			
		}, 1500);
	}

	// Resize full height elements
	fullHeight();
	fadeEffects();
	spinCarousel();

});

$(window).resize(function(){
	if($("body").hasClass('admin')){

	}else{
		posting_slider.reloadSlider();
		upcoming_slider.reloadSlider();
		news_slider.reloadSlider();
		home_slider.reloadSlider();
	}
	fullHeight();
	
});

$(window).on("orientationchange",function(event){
	
	// Get the current height and width of the window
	windowWidth = $(window).width();
	windowHeight = $(window).height();
	
	  if(event.orientation == "landscape"){
		  
		  if(windowWidth < 768){
			  
		  $("#orientation_change").css("display","block");
		  
		  }else{
			  
			  $("#orientation_change").css("display","none");
			  
		  }
		  
	  }else{
		  
		  $("#orientation_change").css("display","none");
		  
	  }
  
});

function fullHeight(){
	
	windowWidth = $(window).width();
	windowHeight = $(window).height();
	
	$(".fullHeight").each(function(){
		
		$(this).height(windowHeight + "px");
		
	});
	
	$("body .square").each(function(){
		
		var thewidth = $(this).width();
		$(this).height(thewidth);
			
	});
	
	$(".height_holder").each(function(){
		var theheight = $(this).find(".height_give").height();
		$(this).find(".height_take").height(theheight);
	});
	
}

function spinCarousel() {  
    $("#carousel .carousel_box:first-child").animate({ marginLeft: -200 }, 3000, 'linear', function () {
        $("#carousel .carousel_box:first-child").appendTo('#carousel');
        $("#carousel .carousel_box:last-child").css('margin-left', 0);
        spinCarousel();
    });
}
var admin_menu_counter = 0;
$("#mob_menu_btn .nav-toggler").click(function(){
	$("#mob_menu_btn").toggleClass("on");
	if(admin_menu_counter == 0){
		$('#mob_menu_btn .nav-toggler').html('<i class="fa fa-times"></i>');
		admin_menu_counter++;
	}else{
		$('#mob_menu_btn .nav-toggler').html('<i class="fa fa-bars ti-close"></i>');
		admin_menu_counter = 0;
	}
});
